<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();

defineProps<{
	game: Game;
}>();
</script>

<template>
	<div class="badges-row">
		<ABadge v-if="game.isNew" autosize background="var(--gradient-5)">
			<AText type="badge-sm">{{ t("New") }}</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.badges-row {
	display: flex;
	align-items: center;
	gap: 4px;
}
</style>
